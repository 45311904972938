import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Menu } from "semantic-ui-react";
import { authState } from "../../atoms/auth";
import { removeUserFromStorage } from "../../services/authService";

function AdminMenus() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  return (
    <React.Fragment>
      <Menu.Item as={Link} to="/admin/dashboard">
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="/admin/conversations">
        <span>
          <Icon name="talk" /> Conversations
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="/admin/users">
        <span>
          <Icon name="users" /> Utilisateurs
        </span>
      </Menu.Item>
      <Menu.Item as="a">
        <span>
          <Icon name="cogs" /> Paramètres
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="/admin/notifications">
        <span>
          <Icon name="bell" /> Notifications
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="/admin/messages">
        <span>
          <Icon name="envelope" /> Messages
        </span>
      </Menu.Item>
      <Menu.Item as="a">
        <span>
          <Icon name="user" /> Mon profile
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="/chat">
        <span>
          <Icon name="chat" /> Chat
        </span>
      </Menu.Item>
      <Menu.Item
        as="a"
        onClick={() => {
          removeUserFromStorage();
          setAuthValue(null);
        }}
      >
        <span>
          <Icon name="log out" color="red" /> Déconnexion
        </span>
      </Menu.Item>
    </React.Fragment>
  );
}

export default AdminMenus;
