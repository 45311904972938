import React, { useState } from "react";
import { FaArrowCircleUp, FaArrowCircleDown } from "react-icons/fa";
import { Icon } from "semantic-ui-react";

const ScrollButton = () => {
  const [showButton, setShowButton] = useState(false);

  const checkScrollTop = () => {
    if (!showButton && window.pageYOffset > 400) {
      setShowButton(true);
    } else if (showButton && window.pageYOffset <= 400) {
      setShowButton(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <>
      {showButton && (
        <button
          class="ui rounded-full bg-gray-700  shadow-md text-white  fixed bottom-28 z-10 right-3 justify-center h-10 w-10 flex items-center flex-col"
          onClick={scrollTop}
        >
         <Icon name="arrow alternate circle up"/>
        </button>
      )}
      {!showButton && (
        <button
          class="ui rounded-full bg-gray-700  shadow-md text-white  fixed bottom-28 z-10 right-3 justify-center h-10 w-10 flex items-center flex-col"
          onClick={scrollDown}
        >
          <Icon name="arrow alternate circle down"/>
        </button>
      )}
    </>
  );
};

export default ScrollButton;
