import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { useFormik } from "formik";
import * as yup from "yup";
import * as authService from "../../services/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as countriesService from "../../services/countriesService";

const registerSchema = yup.object().shape({
  first_name: yup.string().min(3).max(100).required(),
  last_name: yup.string().min(3).max(100).required(),
  email: yup.string().email().max(255).required(),
  phone: yup.string().min(8).required(),
  idcountry: yup.number().required(),
  password: yup.string().min(8).required(),
});

function RegisterFormModal({
  open = false,
  setOpen,
}) {
  const [countries, setCountries] = useState([]);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      idcountry: "",
      password: "",
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      authService
        .register(values)
        .then(
          () => {
            toast(`Compte crée avec succès 👋`, {
              type: "success",
              theme: "colored",
            });

            navigate(`/chat`);
          },
          (reason) => {
            toast(
              `${
                reason?.response?.data ||
                "Une erreur inattendue s'est produite!"
              }`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    handleSubmit,
    handleChange,
  } = formik;

  const fetchCountries = async () => {
    try {
      const response = await countriesService.findAll();
      if (response?.status === 200 && Array.isArray(response.data)) {
        setCountries(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCountries();

    return () => {};
  }, []);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size={"tiny"}
    >
      <Modal.Header>S'inscrire à EduAlly</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"last_name" in errors}>
              <label>Nom</label>
              <Input
                value={values.last_name}
                onChange={handleChange("last_name")}
              />
              <small>{errors.last_name}</small>
            </Form.Field>
            <Form.Field required error={"first_name" in errors}>
              <label>Prénom</label>
              <Input
                value={values.first_name}
                onChange={handleChange("first_name")}
              />
              <small>{errors.first_name}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"values.idcountry" in errors}>
              <label>Pays</label>
              <Form.Select
                search
                options={countries.map((country) => ({
                  key: `${country.id}`,
                  text: `(+${country?.phonecode}) ${country.name} `,
                  value: country.id,
                }))}
                value={values.idcountry}
                onChange={(e, data) => setFieldValue("idcountry", data.value)}
              />
              <small>{errors.idcountry}</small>
            </Form.Field>
            <Form.Field required error={"phone" in errors}>
              <label>Téléphone</label>
              <Input value={values.phone} onChange={handleChange("phone")} />
              <small>{errors.phone}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"email" in errors}>
              <label>Email</label>
              <Input value={values.email} onChange={handleChange("email")} />
              <small>{errors.email}</small>
            </Form.Field>
            <Form.Field required error={"password" in errors}>
              <label>Mot de passe</label>
              <Input
                value={values.password}
                onChange={handleChange("password")}
              />
              <small>{errors.password}</small>
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpen(false);
            formik.resetForm();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          loading={isSubmitting}
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default RegisterFormModal;
