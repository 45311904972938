import { api } from "../axios/api";

export const overview = async (params) => {
  return api.get("/statistics/overview",{params});
};
export const countriesActivities = async (params) => {
  return api.get("/statistics/countries-activities");
};
export const usage = async (params) => {
  return api.get("/statistics/usage",{params});
};
export const getUsageForUser = async (params) => {
  return api.get("/statistics/get-user-usage", { params });
};
export const topTen = async (params) => {
  return api.get("/statistics/top-ten",{params});
};
