import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Grid, Header } from "semantic-ui-react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function CountriesActivitiesBarChart({ activities = {}, height = 100 }) {
  const labels = activities?.questions?.map((activity) => activity.iso3);

  const data = {
    labels,
    datasets: [
      {
        label: "Questions",
        data: activities?.questions?.map((activity) => activity.totalCount),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Réponses",
        data: activities?.responses?.map((activity) => activity.totalCount),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return (
    <Grid.Row>
      <Grid.Column>
        <Header dividing>Activité par pays</Header>
        <Bar data={data} height={height} />
      </Grid.Column>
    </Grid.Row>
  );
}

export default CountriesActivitiesBarChart;
