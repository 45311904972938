import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginPage from "./pages/auth/LoginPage";

import DashboardLayout from "./components/DashboardLayout";

import "react-toastify/dist/ReactToastify.min.css";
import { authState } from "./atoms/auth";
import ProtectedRoute from "./components/ProtectedRoute";
import UsersPage from "./pages/users/UsersPage";
import NotificationsPage from "./pages/nootifications/NotificationsPage";
import MessagesPage from "./pages/messages/MessagesPage";

import PoliciesPage from "./pages/Policies/PoliciesPages";
import Dashboard from "./pages/dashboard/Dashboard";
import ConversationsList from "./pages/conversation/ConversationsList";
import HomePage from "./pages/home/HomePage";
import {
  getUserFromStorage,
  removeUserFromStorage,
} from "./services/authService";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import ChatPage from "./pages/chat/ChatPage";
import UserDashboard from "./pages/users/UserDashboard";

function App() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  useEffect(() => {
    const initAuth = () => {
      try {
        let userData = getUserFromStorage();
        const decodedToken = jwt_decode(userData?.token);
        const today = dayjs();
        const tokenExpireAt = dayjs(decodedToken?.exp * 1000);
        if (today.diff(tokenExpireAt, "day") >= 0) {
          throw new Error("Your token as expired");
        }
        setAuthValue(userData);
      } catch (error) {
        removeUserFromStorage();
        setAuthValue(null);
      }
    };
    initAuth();
    return () => {};
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="connexion" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="policies" element={<PoliciesPage />} />
        <Route
          path={"admin/*"}
          element={
            <ProtectedRoute exept={["ADMIN"]}>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users/*">
            <Route index element={<UsersPage />} />
          </Route>
          <Route path="conversations/*">
            <Route index element={<ConversationsList />} />
          </Route>
          <Route path="notifications/*">
            <Route index element={<NotificationsPage />} />
          </Route>
          <Route path="messages/*">
            <Route index element={<MessagesPage />} />
          </Route>
        </Route>
        <Route
          path={"chat"}
          element={
            <ProtectedRoute exept={["USER","ADMIN"]}>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<ChatPage />} />
        </Route>
        <Route
          path={"users/*"}
          element={
            <ProtectedRoute exept={["USER"]}>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index path="dashboard" element={<UserDashboard />} />
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
