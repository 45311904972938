import React, { useEffect, useRef, useState } from "react";
import { Icon, Image, Menu, Sidebar } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Page from "../../components/Page";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRecoilState } from "recoil";
import { authState } from "../../atoms/auth";
import { removeUserFromStorage } from "../../services/authService";
import RegisterFormModal from "../../components/FormModals/RegisterFormModal";
import Logo from "../../assets/images/chatbot.png";
import Iphone from "../../assets/images/app/header-iphone.png";
import Description1 from "../../assets/images/app/description-1-app.png";
import Description2 from "../../assets/images/app/download-iphone.png";
import Description3 from "../../assets/images/app/568shots_so.png";
import Description4 from "../../assets/images/app/924shots_so.png";

function HomePage() {
  const [authValue, setAuthValue] = useRecoilState(authState);
  const menuRef = useRef();
  const [showRegsiterForm, setShowRegisterForm] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleLogOut = (e) => {
    e.preventDefault();
    removeUserFromStorage();
    setAuthValue(null);
  };
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const handleOnScroll = (event) => {
      if (window.scrollY > 80) {
        menuRef.current?.classList.add("top");
        menuRef.current?.classList.add("fixed");
        menuRef.current?.classList.add("navbar-top-fixed");
      } else {
        menuRef.current?.classList.remove("top");
        menuRef.current?.classList.remove("fixed");
        menuRef.current?.classList.remove("navbar-top-fixed");
      }
    };

    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);

  return (
    <Page title="EduAlly | Votre compagnon qui répond à vos questions!">
      <div className="pl-5 pr-5 md:pl-20 md:pr-20 pt-5 bg-[#f7fafd]">
        <div
          ref={menuRef}
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
          className="ui secondary menu front-p"
        >
          <h3 className="mobile bold self-center text-blue-500" to="/">
            EduAlly
          </h3>
          <Link className="ui item desktop" to="/">
            <Icon name="home" /> Accueil
          </Link>
          {authValue && (
            <Menu.Item as={Link} to="/chat">
              <Icon name="chat" /> Chat
            </Menu.Item>
          )}

          <Menu.Menu className="desktop" position="right">
            {authValue === null && (
              <React.Fragment>
                <Menu.Item as={Link} to="connexion">
                  <Icon name="sign in" /> Se connecter
                </Menu.Item>
                <Menu.Item as="a" onClick={() => setShowRegisterForm(true)}>
                  <Icon name="user" /> S'inscrire
                </Menu.Item>
              </React.Fragment>
            )}
            {authValue && (
              <>
                {authValue?.user?.user_role === "ADMIN" && (
                  <Menu.Item as={Link} to="/admin/dashboard">
                    <Icon name="dashboard" /> Dashboard
                  </Menu.Item>
                )}
                <Menu.Item as={Link} onClick={handleLogOut}>
                  <Icon name="log out" /> Déconnexion
                </Menu.Item>
              </>
            )}
          </Menu.Menu>
          <Menu.Menu className="mobile" position="right">
            <Menu.Item as={Link} onClick={() => setShowSidebar(!showSidebar)}>
              <Icon name="bars" />
            </Menu.Item>
          </Menu.Menu>
        </div>
        <div className="w-full relative">
          <Image
            src={Logo}
            className={"ui centered logo"}
            alt="Logo imge"
            size="tiny"
          />
          <h2 className="font-bold text-center mt-2 mb-3 text-2xl md:text-3xl">
            Bienvenue sur EduAlly
          </h2>
          <p className="text-xl text-center md:ml-20 md:mr-20 text-gray-500 leading-8">
            EduAlly est une application innovante qui vous permet de poser des
            questions à un compagnon de révision virtuel. <br /> Que vous ayez
            besoin d'aide pour réviser vos cours ou que vous cherchiez
            simplement à en savoir plus sur un sujet,
            <br /> EduAlly est là pour vous !
          </p>
          <div className="mt-10 pb-10 md:pb-0 text-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.crinteck.edually"
              className="bg-sky-500 hover:bg-transparent hover:border-sky-500 ease-linear text-white p-5 border rounded-full no-underline text-xl"
            >
              <Icon name="google play" /> Télécharger
            </a>
          </div>
          <span className="bg-orange-500 h-12 w-12 rounded-full absolute top-32 md:top:52 right-7 hidden"></span>
          <span className="bg-white  h-40 w-40 md:h-96 md:w-96 absolute rounded-full left-[0rem] hidden"></span>
          <span className="bg-white h-48 w-48 absolute rounded-full right-[0rem] hidden"></span>
          <span className="border-blue-400 h-20 w-20 border-8 rounded-full absolute hidden"></span>
        </div>
        <div className="w-full mt-24">
          <Image
            src={Description4}
            className={"ui centered logo"}
            alt="Iphone"
            size="huge"
          />
        </div>
        <div className="w-full mt-24">
          <div className="flex gap-10 md:gap-20 items-center justify-center flex-wrap">
            <div className="flex flex-col justify-center items-center">
              <div className="bg-violet-100 h-[120px] w-[120px] rounded-full flex justify-center items-center">
                <Icon name="users" size="huge" className="text-violet-500" />
              </div>
              <div className="font-bold text-center mt-4 text-2xl">
                Actives users
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="bg-green-100 h-[120px] w-[120px] rounded-full flex justify-center items-center">
                <Icon name="code" size="huge" className="text-green-500" />
              </div>
              <div className="font-bold text-center mt-4 text-2xl">
                Situation Analysis
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="bg-red-100 h-[120px] w-[120px] rounded-full flex justify-center items-center">
                <Icon name="cog" size="huge" className="text-red-500" />
              </div>
              <div className="font-bold text-center mt-4 text-2xl">
                Tasks Settings
              </div>
            </div>
            <div className="flex flex-col justify-center items-center ">
              <div className="bg-orange-100 h-[120px] w-[120px] rounded-full flex justify-center items-center">
                <Icon name="chat" size="huge" className="text-orange-500" />
              </div>
              <div className="font-bold text-center mt-4 text-2xl">
                Social Interaction
              </div>
            </div>
            <div className="flex flex-col justify-center items-center ">
              <div className="bg-sky-100 h-[120px] w-[120px] rounded-full flex justify-center items-center">
                <Icon name="rocket" size="huge" className="text-sky-500" />
              </div>
              <div className="font-bold text-center mt-4 text-2xl">
                Get Things Done
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-32">
          <div className="w-full flex justify-between flex-col md:flex-row items-center   flex-wrap">
            <div className="md:w-1/2">
              <Image
                src={Description3}
                className={"ui centered logo"}
                alt="Iphone"
                size="big"
              />
            </div>
            <div className="md:w-1/2 p-4 mt-10">
              <h3 className="font-bold text-2xl md:text-5xl mt-4 mb-4">
                Apprenez sans limites das les domaines qui vous passionne!
              </h3>
              <p className="leading-7 mt-4 text-xl">
                EduAlly vous permet de poser des questions sur n'importe quel
                sujet et de recevoir une réponse précise et détaillée. Vous
                pouvez également écouter les réponses en audio, ce qui peut être
                particulièrement utile si vous préférez écouter plutôt que de
                lire.
              </p>
              <p className="leading-7 mt-4 text-xl">
                En plus de répondre à vos questions, EduAlly vous propose
                également des fonctionnalités supplémentaires pour vous aider à
                réviser vos cours. Vous pouvez par exemple utiliser des
                flashcards pour mémoriser les concepts clés ou réaliser des
                quizz pour tester vos connaissances.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#f7fafd] mt-9 md:mt-0 md:p-28">
          <h3 className="text-center font-bold text-5xl">Témoignages</h3>
          <div className="mt-12 flex flex-col md:flex-row justify-center items-center gap-10 text-center text-gray-700 italic text-xl ">
            <div>
              <div className="mb-2">
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <hr className="w-12 ml-[40%] mt-3" />
              </div>
              <p>
                EduAlly m'a été d'une grande aide pendant mes révisions pour les
                partiels. J'ai pu poser des questions précises et obtenir des
                réponses détaillées en quelques secondes. C'est vraiment un
                compagnon de choix pour les études !
              </p>
              <div className="font-bold mt-3">Pauline - Etudiante</div>
            </div>
            <div>
              <div className="mb-2">
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <hr className="w-12 ml-[40%] mt-3" />
              </div>
              <p>
                J'ai téléchargé EduAlly pour m'aider à réviser mes cours de
                physique et de mathématiques. Les réponses de l'application sont
                très claires et m'ont permis de mieux comprendre certains
                concepts qui me posaient problème. Je recommande vraiment cette
                application !
              </p>
              <div className="font-bold mt-3">Jean Baptiste - Lycéen</div>
            </div>
            <div>
              <div className="mb-2">
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <Icon name="star" color="yellow" />
                <hr className="w-12 ml-[40%] mt-3" />
              </div>
              <p>
                J'ai recommandé EduAlly à mes élèves pour qu'ils puissent poser
                des questions sur leurs lectures et avoir des réponses précises.
                C'est une excellente manière de les encourager à se poser des
                questions et de les aider à mieux comprendre leurs cours.
              </p>
              <div className="font-bold mt-3">Marie - Prof de français</div>
            </div>
          </div>
        </div>
        <div className="relative mt-20">
          <div class="w-full hidden">
            <div className="bg-orange-500 h-12 w-12 rounded-full absolute top-90 right-7 z-8 top-[-20px]"></div>
            <div className="bg-white h-32 w-32  md:h-96 md:w-96 absolute rounded-full left-[-5rem] md:left-[-15rem]"></div>
            <div className="bg-white h-32 w-32 md:h-96 md:w-96 absolute rounded-full right-[0rem] md:right-[-10rem]"></div>
            <div className="border-blue-400 h-20 w-20 top-[-30px] border-8 rounded-full absolute"></div>
          </div>
        </div>
        <div>
          <div className="w-full mt-28 md:mt-7 hidden">
            <Image
              src={Description2}
              className={"ui centered logo"}
              alt="Iphone"
              size="massive"
            />
          </div>
          <p className="text-xl text-center md:ml-20 md:mr-20 text-gray-500 leading-8">
            Rejoignez dès maintenant la communauté de EduAlly pour accéder à une
            expérience d'apprentissage personnalisée et interactive grâce à
            notre compagnon de révision virtuel disponible 24h/24 et 7j/7 !
            Téléchargez l'application dès maintenant et améliorez votre
            compréhension des sujets qui vous intéressent.
          </p>
          <div className="mt-10 pb-20 text-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.crinteck.edually"
              className="bg-sky-500 hover:bg-transparent hover:border-sky-500 ease-linear text-white p-5 border rounded-full no-underline text-xl"
            >
              <Icon name="google play" /> Télécharger
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="bg-[#f7fafd]">
        <div className="w-full p-10 md:p-20  text-gray-500">
          <div className="flex flex-col  gap-5 md:flex-row md:gap-0  md:flex-wrap">
            <div className="flex flex-col justify-start items-start md:w-[30%]">
              <h3 className="font-bold text-lg">A propos</h3>
              <p className="text-gray-500 mt-3">
                EduAlly est un compagnons qui vous aide
                <br /> au quotidien à apprendre vos leçons.
              </p>
            </div>
            <div className="flex flex-col  justify-start items-start md:w-[30%]">
              <h3 className="font-bold text-lg">Contact Info</h3>
              <div className="mt-3">
                <Icon name="location arrow" />
                Hamdallaye ACI 2000, Bamako, Mali
              </div>
              <div className="mt-3">
                <Icon name="envelope" />
                contact@crinteck.com
              </div>
              <div className="mt-3">
                <Icon name="phone" />
                +223 72 61 12 14
              </div>
            </div>
            <div className="flex flex-col justify-start items-start md:w-[20%]">
              <h3 className="font-bold text-lg">Liens utiles</h3>
              <a className="mt-3" href="/">
                Terms & Conditions
              </a>
              <Link className="mt-3" to="/policies">
                Privacy Policy
              </Link>
              <a className="mt-3" href="/">
                Articles details
              </a>
            </div>
            <div className="flex flex-col justify-start items-start md:w-[20%]">
              <h3 className="font-bold text-lg">Autres applications</h3>
              <a
                className="mt-3"
                href="https://play.google.com/store/apps/details?id=com.crinteck.asqme"
              >
                Asq'me
              </a>
              <a
                className="mt-3"
                href="https://play.google.com/store/apps/details?id=com.crinteck.aloyseFoodsMarket"
              >
                Aloyse Foods Market
              </a>
              <a
                className="mt-3"
                href="https://play.google.com/store/apps/details?id=com.crinteck.freezz"
              >
                Freezz
              </a>
            </div>
          </div>
        </div>
        <div className="p-5">
          <h3 className="font-bold text-gray-500 text-center">
            Copyright © 2020 CRINTECK - All rights reserved
          </h3>
        </div>
      </div>
      <RegisterFormModal
        open={showRegsiterForm}
        setOpen={setShowRegisterForm}
      />
      <Sidebar
        style={{ background: "white" }}
        direction="bottom"
        visible={showSidebar}
        animation="overlay"
        className="front-sidebar"
      >
        <div
          style={{ fontWeight: "bold", fontSize: "1.2rem" }}
          className="ui secondary stackable menu front-p"
        >
          {authValue && (
            <Menu.Item as={Link} to="/chat">
              <Icon name="chat" /> Chat
            </Menu.Item>
          )}

          <Menu.Menu position="right">
            {authValue === null && (
              <React.Fragment>
                <Menu.Item as={Link} to="connexion">
                  <Icon name="sign in" /> Se connecter
                </Menu.Item>
                <Menu.Item as="a" onClick={() => setShowRegisterForm(true)}>
                  <Icon name="user" /> S'inscrire
                </Menu.Item>
              </React.Fragment>
            )}
            {authValue && (
              <>
                {authValue?.user?.user_role === "ADMIN" && (
                  <Menu.Item as={Link} to="/admin/dashboard">
                    <Icon name="dashboard" /> Dashboard
                  </Menu.Item>
                )}
                <Menu.Item as={Link} onClick={handleLogOut}>
                  <Icon name="log out" /> Déconnexion
                </Menu.Item>
              </>
            )}
          </Menu.Menu>
        </div>
      </Sidebar>
    </Page>
  );
}

export default HomePage;
