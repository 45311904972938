import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  Breadcrumb,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import { authState } from "../../atoms/auth";
import CountriesActivitiesBarChart from "../../components/charts/CountriesActivitiesBarChart";
import UsageBarChart from "../../components/charts/UsageBarChart";
import Page from "../../components/Page";
import * as statisticsService from "../../services/statisticsService";

function Dashboard() {
  const [overview, setOverview] = useState();
  const [countriesActivities, setCountriesActivities] = useState();
  const [usage, setUsage] = useState();
  const [topTen, setTopTen] = useState();
  const authStore = useRecoilState(authState);

  const fetchOverview = async () => {
    try {
      const response = await statisticsService.overview();
      if (response.status === 200 && typeof response.data === "object") {
        setOverview(response.data);
      }
    } catch (error) {}
  };
  const fetchCountriesActivities = async () => {
    try {
      const response = await statisticsService.countriesActivities();
      if (response.status === 200) {
        setCountriesActivities(response.data);
      }
    } catch (error) {}
  };
  const fetchUsage = async () => {
    try {
      const response = await statisticsService.usage();
      if (response.status === 200) {
        setUsage(response.data);
      }
    } catch (error) {}
  };
  const fetchTopTen = async () => {
    try {
      const response = await statisticsService.topTen();
      if (response.status === 200) {
        setTopTen(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchOverview();
    fetchCountriesActivities();
    fetchUsage();
    fetchTopTen();
    return () => {};
  }, []);

  return (
    <Page title="Dashboard">
      <GridRow className="DashBoard">
        <Breadcrumb>
          <Breadcrumb.Section link>Admin</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Dashboard</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Grid stackable columns={"equal"}>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Header>
                    <Header.Content>{overview?.users || 0}</Header.Content>
                    <Header.Subheader>Utilisateurs</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Icon name="users" size="big" />
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Header>
                    <Header.Content>{overview?.questions || 0}</Header.Content>
                    <Header.Subheader>Questions</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Icon name="help" size="big" />
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Header>
                    <Header.Content>{overview?.responses || 0}</Header.Content>
                    <Header.Subheader>Réponses</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Icon name="talk" size="big" />
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>

        <Grid.Row style={{ marginTop: "20px" }}>
          <Grid.Column>
            <Segment>
              <UsageBarChart activities={usage} />
            </Segment>
            <Segment>
              <CountriesActivitiesBarChart activities={countriesActivities} />
            </Segment>
            <Segment>
              <Header>Top 10</Header>
              <Divider />
              <>
                <Table striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>Nom</Table.HeaderCell>
                      <Table.HeaderCell>Prénom</Table.HeaderCell>
                      <Table.HeaderCell>E-mail</Table.HeaderCell>
                      <Table.HeaderCell>Phone</Table.HeaderCell>
                      <Table.HeaderCell>Pays</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Requêtes
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Actions{" "}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {topTen?.map((classement, index) => (
                      <Table.Row key={`${classement.idusers}`}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell>{classement.last_name}</Table.Cell>
                        <Table.Cell>{classement.first_name}</Table.Cell>
                        <Table.Cell>{classement.email}</Table.Cell>
                        <Table.Cell>{classement.phone}</Table.Cell>
                        <Table.Cell>{classement.iso3}</Table.Cell>
                        <Table.Cell textAlign="center">
                          {classement.totalCount}
                        </Table.Cell>

                        <Table.Cell textAlign="right">
                          <Dropdown pointing="right" icon="ellipsis vertical">
                            <Dropdown.Menu>
                              <Dropdown.Item icon="eye" content="Détails" />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </GridRow>
    </Page>
  );
}

export default Dashboard;
