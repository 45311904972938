import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import Page from "../../components/Page";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import * as chatService from "../../services/chatService";
import { getMyConversations } from "../../services/conversationsService";
import ScrollButton from "../../components/charts/ScrollButton";

const validationSchema = Yup.object().shape({
  question: Yup.string().min(1).max(1000).required(),
});

function ChatPage() {
  const [readingResponseKey, setReadingResponseKey] = useState(null);
  const [conversations, setConversations] = useState([
    {
      key: Date.now().toString(),
      type: "response",
      text: "Bonjour et bienvenue sur EduAlly ! Comment puis-je vous aider ?",
    },
  ]);

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);
        let question = values.question;
        formik.setFieldValue("question", "");
        setConversations((prev) => [
          ...prev,
          {
            key: Date.now().toString(),
            type: "question",
            text: question,
          },
        ]);

        const response = await chatService.create({ prompt: question });
        if (response.status === 200) {
          setConversations((prevState) => [
            ...prevState,
            {
              key: Date.now().toString(),
              type: "response",
              text: response.data?.choices[0]?.text?.trim(),
            },
          ]);
        }
        formik.resetForm();
      } catch (error) {
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const fetchConversations = async () => {
    try {
      const response = await getMyConversations();
      if (response.status === 200 && typeof response.data === "object") {
        setConversations([
          {
            key: Date.now().toString(),
            type: "response",
            text: "Bonjour et bienvenue sur EduAlly ! Comment puis-je vous aider ?",
          },
          ...response.data.map((cv) => ({
            key: cv.idconversations,
            type: cv.conversation_type,
            text: cv.content,
          })),
        ]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchConversations();
    return () => {};
  }, []);

  //Ecris un composant bouton qui permet de scroller du haut ou vers le bas de la page

  return (
    <Page title="ChatPage">
      <ScrollButton />
      <div className="relative min-h-[100%] max-h-[100%] overflow-hidden ">
        <div
          className="absolute bottom-5  min-h-9 chat-interface w-[95%] md:w-[79%] "
          style={{ position: "fixed" }}
        >
          <FormikProvider values={formik}>
            <form className="ui form flex gap-2 flex-row justify-between items-center align-middle">
              <div className="flex-1">
                <textarea
                  style={{ borderRadius: "50px" }}
                  {...formik.getFieldProps("question")}
                  rows={2}
                ></textarea>
              </div>
              {formik.isSubmitting === false ? (
                <button
                  type="submit"
                  onClick={formik.handleSubmit}
                  className="ui rounded-full bg-slate-600 p-3 shadow-md text-white "
                >
                  <Icon name="send" />
                </button>
              ) : (
                <div class="ui active inline  loader"></div>
              )}
            </form>
          </FormikProvider>
        </div>
        <div className="flex flex-col pb-[100px]">
          {conversations?.map((cv) =>
            cv.type === "response" ? (
              <div key={cv.key} className="max-w-[75%] mt-4  self-start">
                <div
                  style={{ wordWrap: "break-word" }}
                  className="bg-white text-black p-3 rounded-r-[10px] rounded-bl-[10px]"
                >
                  <pre
                    style={{
                      wordWrap: "break-word",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                      fontFamily: 'sans-serif, "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
                    }}
                  >
                    {cv.text}
                  </pre>
                </div>
              </div>
            ) : (
              <div key={cv.key} className="max-w-[75%]  self-end  mt-4 right-0">
                <div className="bg-blue-500 text-white text-right p-3 rounded-l-[10px] rounded-br-[10px]">
                  <pre
                    style={{
                      wordWrap: "break-word",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                      fontFamily: 'sans-serif, "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
                    }}
                  >
                    {cv.text}
                  </pre>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Page>
  );
}

export default ChatPage;
