import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Table,
} from "semantic-ui-react";
import Page from "../../components/Page";
import * as conversationsService from "../../services/conversationsService";

function ConversationsList() {
  const [conversations, setConversations] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState();

  const fetchConversations = async () => {
    try {
      const response = await conversationsService.findAll(
        `page=${activePage}&search=${search}`
      );
      if (response?.status === 200 && typeof response.data === "object") {
        const { pagination, data } = response.data;
        setConversations(data);
        setPagination(pagination);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchConversations();
    return () => {};
  }, [search, activePage]);

  return (
    <Page title="Liste des conversations">
      <Breadcrumb>
        <Breadcrumb.Section link>Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Conversations</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="ConversationsList">
        <GridRow className="Compagnies">
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>
                Liste des conversations [{pagination?.total || 0}]
              </Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input
                placeholder="Rechercher..."
                className="search"
                value={search}
                onChange={(e, data) => setSearch(data.value)}
              >
                <input name="search" />
              </Input>
              {/*  <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            /> */}
            </Grid.Column>
          </Grid>
          <>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Noms & Prénoms</Table.HeaderCell>
                  <Table.HeaderCell>Pays</Table.HeaderCell>
                  <Table.HeaderCell>Contenu</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Actions </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {conversations?.map((conversation, index) => (
                  <Table.Row key={`${conversation?.idconversations}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {conversation?.users.first_name}{" "}
                      {conversation?.users.last_name}
                      <Label
                        style={{ marginLeft: "5px" }}
                        circular
                        empty
                        color={
                          conversation?.users.banned === 0 ? "green" : "red"
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>{conversation?.users.country?.iso3}</Table.Cell>
                    <Table.Cell>{conversation.content?.trim()}</Table.Cell>
                    <Table.Cell>{conversation.conversation_type}</Table.Cell>
                    <Table.Cell>
                      {dayjs(conversation?.created_at).format("DD-MM-YYYY à HH:mm")}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown pointing="right" icon="ellipsis vertical">
                        <Dropdown.Menu>
                          <Dropdown.Item icon="eye" content="Détails" />
                          <Dropdown.Item icon="edit" content="Editer" />
                          <Dropdown.Item icon="trash" content="Supprimer" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="9">
                    <Pagination
                      floated="right"
                      defaultActivePage={activePage}
                      ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                      }}
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      totalPages={pagination?.pageCount}
                      onPageChange={(e, { activePage }) => {
                        setActivePage(activePage);
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default ConversationsList;
