import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Grid, Header } from "semantic-ui-react";
import dayjs from "dayjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function UsageBarChart({ activities = [], height = 100 }) {
  const labels = [];
  const chartData = [];
  const daysInMonth = dayjs().daysInMonth();

  const getDateLabel = (day) =>
    dayjs().startOf("month").add(day, "day").format("DD MMM");
    
  const getActivityCount = (act, label) =>
    dayjs(act.saved_at).format("DD MMM") === label ? act.totalCount : 0;

  for (let day = 0; day < daysInMonth; day++) {
    const label = getDateLabel(day);
    labels.push(label);

    const activity = activities.find((act) => getActivityCount(act, label));
    const activityCount = activity ? activity.totalCount : 0;
    chartData.push(activityCount);
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Activité Mensuelle",
        data: chartData,
        backgroundColor: "#7cccba",
      },
    ],
  };

  return (
    <Grid.Row>
      <Grid.Column>
        <Header dividing>Activité Mensuelle</Header>
        <Bar data={data} height={height} />
      </Grid.Column>
    </Grid.Row>
  );
}

export default UsageBarChart;
