import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import UsageBarChart from "../../components/charts/UsageBarChart";
import Page from "../../components/Page";
import * as statisticsService from "../../services/statisticsService";
import dayjs from "dayjs";

function UserDashboard() {
  const [overview, setOverview] = useState();
  const [usage, setUsage] = useState();
  const [currentDate, setNewDate] = useState([
    dayjs().add(-1, "month").toDate(),
    dayjs().toDate(),
  ]);

  const onChange = (event, data) => {
    setNewDate(data.value);
  };

  const fetchOverview = async () => {
    try {
      const response = await statisticsService.overview({
        startDate: currentDate[0],
        endDate: currentDate[1],
      });
      if (response.status === 200 && typeof response.data === "object") {
        setOverview(response.data);
      }
    } catch (error) {}
  };

  const fetchUsage = async () => {
    try {
      const response = await statisticsService.getUsageForUser({
        startDate: currentDate[0],
        endDate: currentDate[1],
      });
      if (response.status === 200) {
        setUsage(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchOverview();
    fetchUsage();

    return () => {};
  }, [currentDate]);

  return (
    <Page title="UserDashboard">
      <GridRow className="UserDashboard">
        <div className="flex items-center justify-between">
          <SemanticDatepicker
            showOutsideDays
            locale="fr-FR"
            type="range"
            onChange={onChange}
            pointing="left"
            datePickerOnly
            value={currentDate}
          />
          <Breadcrumb>
            <Breadcrumb.Section link>Admin</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>UserDashboard</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <Divider />
        <Grid stackable columns={"equal"}>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Header>
                    <Header.Content>{overview?.tokens || 0}</Header.Content>
                    <Header.Subheader>Tokens</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Icon name="file word" size="big" />
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Header>
                    <Header.Content>{overview?.questions || 0}</Header.Content>
                    <Header.Subheader>Questions</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Icon name="help" size="big" />
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Grid columns={"equal"}>
                <Grid.Column>
                  <Header>
                    <Header.Content>{overview?.responses || 0}</Header.Content>
                    <Header.Subheader>Réponses</Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Icon name="talk" size="big" />
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>

        <Grid.Row style={{ marginTop: "20px" }}>
          <Grid.Column>
            <Segment>
              <UsageBarChart activities={usage} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </GridRow>
    </Page>
  );
}

export default UserDashboard;
